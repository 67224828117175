<template>
  <div>
    <!-- Modal to register user starts -->
    <div
      class="modal fade"
      id="regmodaloverdraft"
      tabindex="-1"
      aria-labelledby="regmodaloverdraft"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none;">
            <!-- <h5 class="modal-title title-modal" id="fundwallet">
              Fund Wallet -->
            <!-- </h5> -->
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="header-text">
            <h6>Register new user</h6>
          </div>
          <div class="modal-body reg-body">
            <form>
              <div class="form-group theform">
                <div class="row">
                  <div class="col-md-6 mb-md-0 mb-4">
                    <label for="firstname" class="d-flex mt-1 label-tags"
                      >First name <span>*</span></label
                    >
                    <input
                      class="form-control input-field"
                      id="firstname"
                      name="firstname"
                      placeholder="Enter first name"
                      v-model="fname"
                    />
                  </div>

                  <div class="col-md-6 mb-md-0 mb-4">
                    <label for="surname" class="d-flex mt-1 label-tags"
                      >Surname <span>*</span></label
                    >
                    <input
                      class="form-control input-field"
                      id="surname"
                      name="surname"
                      placeholder="Enter surname name"
                      v-model="surname"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group theform">
                <label for="email22" class="d-flex mt-1 label-tags"
                  >Email <span>*</span></label
                >
                <input
                  class="form-control input-field"
                  id="email22"
                  name="email"
                  type="email"
                  placeholder="Email"
                  v-model="email2"
                />
              </div>
              <div class="form-group theform">
                <label for="phone" class="d-flex mt-1 label-tags"
                  >Phone number <span>*</span></label
                >
                <input
                  maxlength="11"
                  class="form-control input-field"
                  id="phone"
                  type="number"
                  name="phone"
                  placeholder="Enter phone number"
                  v-model="phone"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </div>
              <div class="form-group theform">
                <label class="d-flex mt-4 label-tags"
                  >Gender <span>*</span></label
                >
                <div class="text-justify">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="male"
                      id="male"
                      v-model="gender"
                      value="male"
                    />
                    <label class="form-check-label radio-label" for="male"
                      >Male</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="gender"
                      class="form-check-input"
                      type="radio"
                      name="female"
                      id="female"
                      value="female"
                    />
                    <label class="form-check-label radio-label" for="female"
                      >Female</label
                    >
                  </div>
                </div>
              </div>

              <div class="form-group d-none">
                <label class="d-flex mt-1 label-tags" for="meansofid"
                  >Select means of identification</label
                >
                <select class="form-control select-option" id="meansofid">
                  <option selected disabled :value="null">--Select--</option>
                  <option>Driver's licence</option>
                  <option>NIN</option>
                  <option>International passport</option>
                  <!-- <option
                    v-for="(item, index) in modeOfSafeProject"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</option
                  > -->
                </select>
              </div>
              <div class="form-group d-none" @click="$refs.moid.click()">
                <label class="d-flex mt-1 label-tags" for="meansofid"
                  >Select means of identification</label
                >
                <div class="upload-section">
                  <input
                    id="moid"
                    name="moid"
                    type="file"
                    ref="moid"
                    class="d-none"
                  />
                  <img src="../../assets/icons/file-upload.svg" alt="upload" />
                  <p>Click to upload</p>
                  <h6>PNG, JPEG or JPG</h6>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer" style="border: none;">
            <!-- @click="registerUser()" -->
            <!-- data-toggle="modal"
            data-target="#linkcard" -->
            <button
              :disabled="userloading"
              @click="registerUser()"
              type="button"
              class="btn btn-block continue-btn"
            >
              Submit
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="userloading"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to register user ends -->

    <!-- Modal to link card starts -->
    <div
      class="modal fade"
      id="linkcard"
      tabindex="-1"
      aria-labelledby="linkcard"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none;">
            <!-- <h5 class="modal-title title-modal" id="fundwallet">
              Fund Wallet -->
            <!-- </h5> -->
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="header-text">
            <h6>Link Card</h6>
          </div>
          <div class="modal-body reg-body">
            <form>
              <div class="form-group theform">
                <label for="name" class="d-flex mt-1 label-tags">Name</label>
                <input
                  disabled
                  class="form-control input-field"
                  id="name"
                  name="name"
                  placeholder="First name"
                  v-model="fname"
                />
              </div>

              <div class="form-group theform">
                <label
                  v-if="!pinloader"
                  for="surname"
                  class="d-flex mt-1 label-tags"
                  >Card pin</label
                >
                <label
                  v-else
                  for="surname"
                  class="d-flex mt-1 label-tags loading-card-details"
                  >Loading card details</label
                >
                <div class="input-group">
                  <input
                    class="form-control input-field"
                    id="cardnumber"
                    type="number"
                    name="cardnumber"
                    placeholder="Card pin"
                    v-model="cardpin"
                  />
                  <div class="input-group-append">
                    <img
                      class="input-group-text"
                      id="basic-addon2"
                      style="background: none; cursor: pointer;"
                      src="../../assets/icons/scan-barcode.svg"
                      alt="scan"
                    />
                  </div>
                </div>
              </div>
              <div v-if="pindetailsshow">
                <div class="form-group theform">
                  <label for="cardtype" class="d-flex mt-1 label-tags"
                    >Card type</label
                  >
                  <div
                    v-if="carddetails.cardtype"
                    class="form-control input-field text-left pt-2.5"
                  >
                    {{ carddetails.cardtype.cardname }}
                  </div>
                </div>
                <div class="form-group theform">
                  <label for="cardamount" class="d-flex mt-1 label-tags"
                    >Card amount</label
                  >
                  <div
                    v-if="carddetails.cardtype"
                    class="form-control input-field text-left pt-2.5"
                  >
                    ₦{{ carddetails.cardtype.amount }}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer" style="border: none;">
            <button
              v-if="this.user_user_id && this.cardpin"
              :disabled="
                userloading2 ||
                  !pindetailsshow ||
                  (this.user_user_id == '' && this.cardpin == '')
              "
              @click="linkCard()"
              type="button"
              class="btn btn-block continue-btn"
            >
              Link card
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="userloading2"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to link card ends -->

    <!-- confirmation modal starts -->
    <div
      class="modal fade"
      id="responsemodal"
      tabindex="-1"
      aria-labelledby="fundwallet"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="header-img">
            <img src="../../assets/icons/success-icon.svg" alt="success" />
          </div>
          <div class="modal-body response-body">
            <h5>Registration successful</h5>
            <p>
              Registration completed successfully. To finalize the process, link
              the user's physical Ovadraft card to their account using the
              button below.
            </p>
          </div>
          <div class="modal-footer" style="border: none; flex-wrap: nowrap;">
            <button type="button" class="btn cancel-btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              class="btn continue-btn"
              @click="openLinkCardModal()"
            >
              Assign & link Card
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- confirmation modal ends -->

    <!-- card linked success modal starts -->
    <div
      class="modal fade"
      id="successlinked"
      tabindex="-1"
      aria-labelledby="successlinked"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="header-img">
            <img src="../../assets/icons/success-icon.svg" alt="success" />
          </div>
          <div class="modal-body response-body">
            <h5>Card linked & assigned successfully</h5>
            <p class="mb-0">
              Card has been successfully linked & assigned to the customer’s
              account.
            </p>
          </div>
          <div class="modal-footer" style="border: none; flex-wrap: nowrap;">
            <button
              type="button"
              class="btn btn-block continue-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- card linked success modal ends -->
  </div>
</template>

<script>
import { Service } from "../../store/service";
import moment from "moment";
import $ from "jquery";
const Api = new Service();
// import paystack from "vue-paystack";
// import { staticdata } from "../../src/data";
export default {
  name: "NewUserModal",
  components: {
    // paystack
  },
  props: [
    "dataForRegistration",
    "paystackReference",
    "action",
    "responseData",
    "closee"
  ],
  data() {
    return {
      loader: false,
      userloading: false,
      userloading2: false,
      pinloader: false,
      pindetailsshow: false,
      filePath: "",
      file: "",
      registrationData: "",
      // PUBLIC_KEY: staticdata.paystackkeytest,
      // PUBLIC_KEY: staticdata.paystackkeylive,
      email: "",
      email2: "",
      fullName: "",
      loadingState: this.responseData,
      fname: "",
      surname: "",
      gender: "",
      phone: "",
      cardpin: "",
      carddetails: "",
      user_user_id: "",
      cardDetailsParent: ""
    };
  },
  computed: {
    welcome() {
      return `${this.dataForRegistration}`;
    }
  },
  // beforeMount() {},
  watch: {
    cardpin: function(newValue, oldValue) {
      if (newValue.length === 12) {
        this.getDetails();
      }
    }
  },
  created() {
    this.$root.$refs.usermodal = this;
    this.$root.$refs.linkcardmodal = this;
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.email = this.user.email;
    this.fullName = this.user.firstname + this.user.lastname;
    this.dataForRegistration === this.dataForRegistration;
    this.responseData === this.responseData;
    this.routePath = this.$route.path;
    this.routeName = this.$route.name;
  },
  beforeDestroy() {
    this.loadingState = ""; // save props data to itself's data and deal with it
  },
  methods: {
    openRegistrationModal() {
      $("#regmodaloverdraft")
        .addClass("fade")
        .modal("show");
    },

    closeModalForOverdaraft() {
      $("#regmodaloverdraft")
        .removeClass("fade")
        .modal("hide");
    },
    openLinkCardModal() {
      $("#responsemodal")
        .removeClass("fade")
        .modal("hide");
      $("#linkcard")
        .addClass("fade")
        .modal("show");
    },
    openLinkCardModal2(item, parentCardDetails) {
      $("#responsemodal")
        .removeClass("fade")
        .modal("hide");
      $("#linkcard")
        .addClass("fade")
        .modal("show");
      this.user_user_id = item.id;
      this.fname = item.firstname;
      this.cardDetailsParent = parentCardDetails;
    },
    async getDetails() {
      this.pinloader = true;
      try {
        await Api.getRequest(`getcarddetailsbypin/${this.cardpin}`)
          .then(res => {
            console.log("card details", res);
            this.carddetails = res.data.card;
            this.pinloader = false;
            if (res.data.success) {
              return (this.pindetailsshow = true);
            }
            if (res.data.error) {
              this.pindetailsshow = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pinloader = false;
            this.pindetailsshow = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async linkCard() {
      this.userloading2 = true;
      if (this.cardDetailsParent != undefined) {
        if (
          this.cardDetailsParent.cardtype.cardname !=
          this.carddetails.cardtype.cardname
        ) {
          this.userloading2 = false;

          return this.$store.commit(
            "setApiFailed",
            "Card type don't match with user's request"
          );
        }
      }
      try {
        await Api.postRequest(`linkcardtouser`, {
          user_user_id: this.user_user_id,
          card_pin: this.cardpin
          // user_user_id: 3389,
          // card_pin: 324040408265
        })
          .then(res => {
            this.userloading2 = false;
            if (res.data.success) {
              this.$router.go();
              if (this.$route.name == "PaddingLinkedRequests") {
                this.$root.$refs.usersCreated.getRegisteredUser();
              }
              $("#linkcard")
                .removeClass("fade")
                .modal("hide");
              $("#successlinked")
                .addClass("fade")
                .modal("show");
            }
            if (res.data.error == "card is linked already") {
              this.pindetailsshow = false;
            }
            if (res.data.error) {
              // $("#linkcard")
              //   .removeClass("fade")
              //   .modal("hide");
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.userloading2 = false;
            console.log(err);
            $("#linkcard")
              .removeClass("fade")
              .modal("hide");
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("close");
      }
    },
    async registerUser() {
      this.userloading = true;
      if (this.fname === "") {
        this.userloading = false;
        return this.$store.commit("setApiFailed", "First name required");
      }
      if (this.surname === "") {
        this.userloading = false;
        return this.$store.commit("setApiFailed", "Surname is required");
      }
      if (this.email2 === "") {
        this.userloading = false;
        return this.$store.commit("setApiFailed", "Email is required");
      }
      const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!validRegex.test(this.email2)) {
        this.userloading = false;
        return this.$store.commit("setApiFailed", "Invalid Email address!");
      }
      if (this.phone === "") {
        this.userloading = false;
        return this.$store.commit("setApiFailed", "Phone is required");
      }
      if (this.gender === "") {
        this.userloading = false;
        return this.$store.commit("setApiFailed", "Gender is required");
      }
      if (this.phone.length < 11) {
        this.userloading = false;
        return this.$store.commit(
          "setApiFailed",
          "Phone number is less than eleven digit"
        );
      }
      //   return this.$emit("action", { type: "payfromsafe", action: true });
      try {
        await Api.postRequest(`registeroverdraftuser`, {
          firstname: this.fname,
          lastname: this.surname,
          email: this.email2,
          phone: this.phone,
          // dob: this.dob,
          gender: this.gender
        })
          .then(res => {
            this.userloading = false;
            if (res.data.success) {
              (this.fname = res.data.user.firstname),
                (this.user_user_id = res.data.user.id),
                $("#regmodaloverdraft")
                  .removeClass("fade")
                  .modal("hide");
              $("#responsemodal")
                .addClass("fade")
                .modal("show");
                this.$router.go();
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.userloading = false;
            console.log(err);
            $("#regmodaloverdraft")
              .removeClass("fade")
              .modal("hide");
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("closedd");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$limeGreen: #3ec250;
$sheltaGrey: #555c74;
$offBlack: #2b3352;

.header-text {
  padding-left: 15px;
  text-align: left;
  color: $offBlack;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.label-tags {
  color: $offBlack;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 4px;
  span {
    color: red;
    margin-left: 4px;
    font-size: 16px;
    margin-top: 4px;
  }
}

.loading-card-details {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.input-field,
.select-option {
  height: 48px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  color: #667085;
  outline: 0;
  box-shadow: none;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  // line-height: 150%;
}
.theform {
  margin-bottom: 24px;
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
}
.radio-label {
  color: $sheltaBlack;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.reg-body {
  padding: 15px 24px;
}

.upload-section {
  border-radius: 6px;
  border: 1px dashed #ccd6fb;
  background: $secondary;
  padding: 16px;
  cursor: pointer;
  p {
    color: $primary;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0;
  }
  h6 {
    color: $offBlack;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
  }
}

.cancel-btn {
  height: 48px;
  border-radius: 8px;
  background: $secondary;
  border: 1px solid #ddd;
  color: #000a2f;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  outline: 0;
  box-shadow: none;
  width: 100%;
}
.continue-btn {
  border-radius: 6px;
  background: $primary;
  color: $secondary;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  height: 48px;
  outline: 0;
  box-shadow: none;
  width: 100%;
}
// Registratin form ends

// Response modal starts
.response-body {
  padding: 24px 40px 40px;
  h5 {
    color: #000a2f;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  p {
    color: #555c74;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 40px;
  }
}
// Respnse modal ends

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
